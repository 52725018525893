@font-face {
    font-family: 'Visby';
    src: url('/fonts/VisbyCF-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: 'Visby';
    src: url('/fonts/VisbyCF-Bold.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Visby';
    src: url('/fonts/VisbyCF-DemiBold.woff2') format('woff2');
    font-weight: 500;
    font-display: swap;
}