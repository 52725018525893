html,
body {
    padding: 0;
    margin: 0;
    font-family: Visby, sans-serif;
    overflow-x: hidden;
    position: relative;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

header {
    position: relative;
}

main {
    position: relative;
    z-index: 1;
}

.authcode-container {
    display: flex;
    gap: 12px;
    width: 100%;
    margin: 20px auto;
    z-index: 1;
}

.authcode-input {
    flex: 1 1;
    width: 100%;
    height: 70px;
    color: black;
    border: 2px solid black;
    border-radius: 3px;
    text-align: center;
    text-transform: uppercase;
    padding: 0;
    font-family: Visby, sans-serif;
    font-size: 24px;
}
.authcode-input:focus, .authcode-input:active, .authcode-input:focus-within, .authcode-input:target {
    border-color: var(--colors-primary) !important;
    outline: none;
}
